import {PickThemeColor} from './Utils';

export function HoldingPage () {
    const theme_color = PickThemeColor();

    return (
        <div className={`page-full page-bg ${theme_color}`}>
            <div id='holding-page-inner'>
                <h3>
                    Coming soon...
                </h3>
            </div>
        </div>
    )

}