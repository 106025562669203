export const COLORS = ['green', 'orange', 'blue', 'pink'];

export function PickThemeColor(current_theme_color=false) {

    let other_colors = COLORS;
    if (current_theme_color) {
        // exclude the current color from the choices if it is set
        other_colors = COLORS.filter(c => c !== theme_color);
    }

    return other_colors[Math.floor(Math.random() * other_colors.length)];
}