import {useState} from 'react';
import { AuthPage } from './AuthPage';
import { HoldingPage } from './HoldingPage';
export function App() {

    const [authd, setAuthd] = useState(false);

    return <HoldingPage></HoldingPage>

    if (!authd) {
        return <AuthPage authd={authd} setAuthd={setAuthd}/>
    } 

    return (
        <div className="page-col">
            <h1>HEADING.</h1>
            <p>Body text.</p>
        </div>
    )
  }